// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interest-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 15px;
  row-gap: 15px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 100px;
  /* background-color: white; */
}

/* .name {
  position: absolute;
  top: 0px;
  left: 0;
} */
.form-check {
  position: relative;
}
.form-check-input {
  position: absolute;
  top: 25px;
  left: 5px;
  z-index: 10;
  height: 20px;
  width: 20px;
}
.form-check-label {
}

.interest-box {
  /* filter: grayscale(30%); */
  opacity: 0.7;
  width: auto;
  height: 150px;
  background-color: #011525;
  margin-top: 20px;
  border-radius: 15px;
  position: relative;
  /* filter: hue-rotate(); */
}
/* .interest-box:hover {
  opacity: 1;
  filter: grayscale(0);
  transform: scale(1.07, 1.07);
  background-color: #011e34;
  color: black;
} */
.interest-box-img {
  height: 100%;
  width: 100%;
  border-radius: 15px;
  color: black;
}
`, "",{"version":3,"sources":["webpack://./src/Components/InterestPage/Interest.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;;;;GAIG;AACH;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,WAAW;EACX,YAAY;EACZ,WAAW;AACb;AACA;AACA;;AAEA;EACE,4BAA4B;EAC5B,YAAY;EACZ,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,0BAA0B;AAC5B;AACA;;;;;;GAMG;AACH;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,YAAY;AACd","sourcesContent":[".interest-main-container {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  column-gap: 15px;\n  row-gap: 15px;\n  margin-left: 20px;\n  margin-right: 20px;\n  margin-top: 100px;\n  /* background-color: white; */\n}\n\n/* .name {\n  position: absolute;\n  top: 0px;\n  left: 0;\n} */\n.form-check {\n  position: relative;\n}\n.form-check-input {\n  position: absolute;\n  top: 25px;\n  left: 5px;\n  z-index: 10;\n  height: 20px;\n  width: 20px;\n}\n.form-check-label {\n}\n\n.interest-box {\n  /* filter: grayscale(30%); */\n  opacity: 0.7;\n  width: auto;\n  height: 150px;\n  background-color: #011525;\n  margin-top: 20px;\n  border-radius: 15px;\n  position: relative;\n  /* filter: hue-rotate(); */\n}\n/* .interest-box:hover {\n  opacity: 1;\n  filter: grayscale(0);\n  transform: scale(1.07, 1.07);\n  background-color: #011e34;\n  color: black;\n} */\n.interest-box-img {\n  height: 100%;\n  width: 100%;\n  border-radius: 15px;\n  color: black;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
